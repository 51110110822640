<template>
  <div class="main-content">
    <h3 class="page-heading">Platform Users</h3>
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Last Login</th>
          <th>Failed Logins</th>
          <th>Claims</th>
          <th class="fit"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in this.users" :key="user.id">
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ formatDateTime(user.lastLogin) }}</td>
          <td>{{ user.failedLogins }}</td>
          <td>{{ user.claims }}</td>
          <td class="fit">
            <button>Reset</button>
            <button>Lock</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import store from "@/store";

  export default {
    data() {
      return {
        users: [],
      };
    },
    mounted() {
      store.state.apiPrivate.client.endpoints.users
        .get()
        .then((response) => {
          if (response.status >= 200 && response.status <= 204) {
            return response.data.data;
          } else {
            return Promise.reject();
          }
        })
        .then((data) => {
          this.users = data;
        })
        .catch((err) => {
          window.log.error("Failed to download users.", err);
        });
    },
  };
</script>
